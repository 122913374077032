<style lang="less">
.adminListTree {
  overflow: auto;
  height: 150px;
  border: 1px solid #cccccc;
}
</style>
<template>
  <div class="adminList">
    <div class="table-button">
      <Button type="primary" @click="toAdd()">新增</Button>
    </div>
    <div class="table-input">
      <Input v-model="param.name" clearable placeholder="名称" />
      <Button type="primary" @click="search()">搜索</Button>
    </div>
    <Table :columns="columns" :data="data" @on-selection-change="changeSelection">

      <template slot-scope="{ row, index }" slot="headUrl">
        <img :src="item" style="width: 30px;height: 30px;margin:5px 5px 0 5px;cursor: zoom-in;" v-for="item,index in row.headUrlArr" :key="index" @click="MyUtil.showBigImg(item)" />
      </template>

      <template slot-scope="{ row, index }" slot="type">
        <span v-if="row.type==1">平台</span>
        <span v-if="row.type==2">商家</span>
      </template>

      <template slot-scope="{ row, index }" slot="status">
        <span v-if="row.status==1" style="color:green">启用</span>
        <span v-if="row.status==0" style="color:red">禁用</span>
      </template>

      <template slot-scope="{ row, index }" slot="action">
        <Button v-if="row.status==1" type="primary" size="small" @click="toStatus(row)">禁用</Button>
        <Button v-if="row.status==0" type="primary" size="small" @click="toStatus(row)">启用</Button>
        <Button type="primary" size="small" @click="toUpdate(row)">修改</Button>
        <Button type="primary" size="small" @click="toDelete(row)">删除</Button>
      </template>
    </Table>
    <Page :total="total" :current="param.page" :page-size="param.rows" show-total show-sizer @on-change="changePage" @on-page-size-change="changePageSize"></Page>

    <!-- 模态框 -->
    <Modal v-model="modal" :title="modalTitle" width="600">
      <Form ref="form" :model="form" :rules="formRules" :label-width="80">
        <FormItem label="账号：" prop="account" style="width: 80%">
          <Input v-model="form.account"></Input>
        </FormItem>
        <FormItem label="密码：" :prop="form.id==null?'password':''" style="width: 80%">
          <Input v-model="form.password" :placeholder="form.id==null?'':'不填写则不修改密码'"></Input>
        </FormItem>
        <FormItem label="姓名：" prop="name" style="width: 80%">
          <Input v-model="form.name"></Input>
        </FormItem>
        <FormItem label="手机号：" prop="phone" style="width: 80%">
          <Input v-model="form.phone"></Input>
        </FormItem>
        <FormItem label="菜单：" prop="webMenuIds" style="width: 80%">
          <Tree ref="tree" v-model="form.webMenuIds" :data="dataTree" show-checkbox class="adminListTree" @on-check-change="onCheckChange"></Tree>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="value=>this.modal=false">关闭</Button>
        <Button type="primary" @click="save()">确定</Button>
      </div>
    </Modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      MyUtil: this.MyUtil,
      //列表
      deleteIds: [],
      param: {
        name: null,
        page: 1,
        rows: 10,
      },
      total: 0,
      data: [],
      columns: [
        {
          type: "selection",
          width: 35,
        },
        {
          title: "账号",
          key: "account",
          width: 200,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "姓名",
          key: "name",
          width: 200,
        },
        {
          title: "手机号",
          key: "phone",
          width: 200,
        },
        {
          title: "菜单",
          key: "webMenuNames",
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "状态",
          slot: "status",
          width: 100,
        },
        {
          title: "操作",
          slot: "action",
          fixed: "right",
          width: 180,
        },
      ],

      //模态框
      modal: false,
      modalTitle: "",
      dataTree: [],
      form: {
        account: null,
        password: null,
        name: null,
        phone: null,
        webMenuIds: null,
      },
      formRules: {
        account: [
          {
            required: true,
            message: "此项不能为空",
          },
        ],
        password: [
          {
            required: true,
            message: "此项不能为空",
          },
        ],
        name: [
          {
            required: true,
            message: "此项不能为空",
          },
        ],
        phone: [
          {
            required: true,
            message: "此项不能为空",
          },
        ],
        webMenuIds: [
          {
            required: true,
            message: "此项不能为空",
          },
        ],
      },
    };
  },
  methods: {
    getWebMenuTreeAll() {
      this.MyUtil.ajax()
        .post("/UserController/getWebMenuTreeAll", this.param)
        .then((resData) => {
          this.dataTree = resData;
        });
    },

    getDataList() {
      this.MyUtil.ajax()
        .post("/UserController/getAdministratorList", this.param)
        .then((resData) => {
          this.data = resData.data;
          this.total = resData.total;
          if (this.data) {
            this.data.forEach((element) => {
              if (element.url) {
                element.headUrlArr = element.url.split(",");
              }
            });
          }
        });
    },
    changePage(page) {
      this.param.page = page;
      this.getDataList();
    },
    changePageSize(rows) {
      this.param.rows = rows;
      this.getDataList();
    },
    changeSelection(selection) {
      this.deleteIds = [];
      selection.forEach((element) => {
        this.deleteIds.push(element.id);
      });
    },
    search() {
      this.param.page = 1;
      this.getDataList();
    },

    toStatus(row) {
      this.$Modal.confirm({
        title: "操作确认",
        content: "点击确认，以继续执行该操作！",
        onOk: () => {
          this.MyUtil.ajax()
            .post("/UserController/updateAdministratorStatus", {
              id: row.id,
            })
            .then((resData) => {
              this.$Message.success("操作成功");
              this.getDataList();
            });
        },
      });
    },

    toAdd() {
      this.$refs.form.resetFields();
      this.$refs.form.resetFields();
      this.modalTitle = "新增";
      this.form.id = null;
      this.resetTree();
      this.modal = true;
    },

    toUpdate(row) {
      this.$refs.form.resetFields();
      this.$refs.form.resetFields();
      this.modalTitle = "修改";
      this.form.id = row.id;
      this.form.account = row.account;
      this.form.password = null;
      this.form.name = row.name;
      this.form.phone = row.phone;
      this.form.webMenuIds = row.webMenuIds;
      this.resetTree(this.form.webMenuIds);
      this.modal = true;
    },

    toDelete(row) {
      let ids = [];
      if (row) {
        ids.push(row.id);
      } else {
        if (this.deleteIds.length < 1) {
          this.$Message.warning("请至少选择一条数据");
          return;
        }
        ids = this.deleteIds;
      }
      this.$Modal.confirm({
        title: "操作确认",
        content: "点击确认，以继续执行该操作！",
        onOk: () => {
          this.MyUtil.ajax()
            .post("/UserController/deleteAdministrator", {
              deleteIds: ids,
            })
            .then((resData) => {
              this.$Message.success("删除成功");
              this.getDataList();
              this.deleteIds = [];
            });
        },
      });
    },

    resetTree(ids) {
      let arr = this.MyUtil.copy(this.dataTree);
      if (arr instanceof Array) {
        arr.forEach((element) => {
          element.expand = false;
          if (("," + ids + ",").indexOf("," + element.id + ",") != -1) {
            element.checked = true;
          } else {
            element.checked = false;
          }
          if (element.children) {
            element.children.forEach((elementA) => {
              elementA.expand = false;
              if (("," + ids + ",").indexOf("," + elementA.id + ",") != -1) {
                elementA.checked = true;
              } else {
                elementA.checked = false;
              }
            });
          }
        });
      }
      this.dataTree = arr;
    },
    onCheckChange(nodes, node) {
      if (nodes && nodes.length > 0) {
        let arr = [];
        nodes.forEach((element) => {
          arr.push(element.id);
        });
        this.form.webMenuIds = arr.join(",");
      } else {
        this.form.webMenuIds = null;
      }
      this.$refs.form.validateField("webMenuIds");
    },

    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.MyUtil.ajax()
            .post("/UserController/addAdministrator", this.form)
            .then((resData) => {
              this.$Message.success("保存成功");
              this.getDataList();
              this.modal = false;
            });
        }
      });
    },
  },
  created() {
    this.getDataList();
    this.getWebMenuTreeAll();
  },
};
</script>
